import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Histogram from '../Histogram';

import { useProjectContext } from '../../context/ProjectContext';

const TableauSynthetique = ({ 
  graph,
  total,
  className = '',
  ...props
}) => {
  // ---------------------------------
  // ---------------------------------
  const { colors } = useProjectContext();
  
  const toLocaleString = (num) => num === false ? '-' : num.toLocaleString();
  
  // ---------------------------------
  // ---------------------------------
  const renderTableau = (total) => {
    
    if (!total) {
      return null;
    }
    
    if (!total.items) {
      return null;
    }
    
    
    let { items } = total;
    if (!items) {
      return null;
    }
    
    return (
      <div className={"capeb-tableau-synthetique"}>
        <table className={"capeb-tableau-synthetique__table"}>
          <thead>
            <tr className="capeb-tableau-synthetique__head">
              <th className="capeb-tableau-synthetique__label"></th>
              <th className="capeb-tableau-synthetique__value">Total</th>
              <th className="capeb-tableau-synthetique__value">Moyenne</th>
              <th className="capeb-tableau-synthetique__value">Max</th>
              <th className="capeb-tableau-synthetique__value">Min</th>
              <th className="capeb-tableau-synthetique__value">Evolution {(total.currentYear && total.lastYear) ? `${total.lastYear} - ${total.currentYear}`: ''}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="capeb-tableau-synthetique__category">
              <td colSpan={6}>{total.category + (total.category && total.currentYear ? ` - ${total.currentYear}`: '')}</td>
            </tr>
            <tr className="capeb-tableau-synthetique__subcategory">
              <td colSpan={6}>{total.subCategory}</td>
            </tr>
            {total.items.map(({ question, subCategory, total, avg, min, max, evolution }) => (
              <tr className={"capeb-tableau-synthetique__row capeb-tableau-synthetique__number"} key={question}>
                <td className="capeb-tableau-synthetique__label">{question}</td>
                <td className="capeb-tableau-synthetique__value">{toLocaleString(Math.round(total))} {(subCategory || '').includes('financier') ? '€' : ''}</td>
                <td className="capeb-tableau-synthetique__value">{toLocaleString(Math.round(avg))} {(subCategory || '').includes('financier') ? '€' : ''}</td>
                <td className="capeb-tableau-synthetique__value">{toLocaleString(Math.round(max))} {(subCategory || '').includes('financier') ? '€' : ''}</td>
                <td className="capeb-tableau-synthetique__value">{toLocaleString(Math.round(min))} {(subCategory || '').includes('financier') ? '€' : ''}</td>
                <td className="capeb-tableau-synthetique__value">{toLocaleString(Math.round(evolution))} %</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ); 
  };
  
  return (
    <div className={`${className}`} {...props}>
      <Container>
        <Row>
          <Col>
            {renderTableau(total)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TableauSynthetique;