/* global addSpacesToNumber */
import React from 'react';

import { customRound } from '../../helpers/legend';
import ResumeBlock from '../../components/ResumeBlock';

const CapebResumeBlock = ({ graph, total, type, ...props }) => {
    if (!total || !total.selected) {
        return null;
    }

    const { selected, compare } = total;
    const config = [];

    switch (graph.type) {
        case 'capeb-taux-mise-oeuvre-global-dep':
        case 'capeb-taux-mise-oeuvre-global-reg':
            config.push({
                title: `Taux de mise en oeuvre ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.total, graph.type),
                label: selected.nonDefinie ? 'NR' : addSpacesToNumber(customRound(selected.total, graph.type)) + '%',
            });
            break;
        case 'capeb-nombre-adherent-et-evolution-dep':
            config.push({
                title: `Nombre d'adhérents en ${selected.year}${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.total, graph.type),
                label: addSpacesToNumber(customRound(selected.total, graph.type)),
            });
            config.push({
                title: 'Evolution ' + selected.lastYear + '-' + selected.year,
                value: customRound(selected.evolution, graph.type),
                label: addSpacesToNumber(customRound(selected.evolution, graph.type)) + '%',
                type: 'evolution',
            });
            break;
        case 'capeb-taux-syndicalisation-dep':
            config.push({
                title: `Taux de syndicalisation ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'charge-personnelle-charge-exploitation-dep':
        case 'capeb-charge-personnelle-charge-exploitation-reg':
            config.push({
                title: `Charges de personnel / Charges d'exploitation ${!selected.zone ? 'moyen' : ''} en ${
                    selected.year
                } ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-pourcentage-adherent-travaillant-dep':
            config.push({
                title: `Pourcentage d’adhérents travaillant seul ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-pourcentage-adherents-employeurs-nombre-adherents-dep':
            config.push({
                title: `Pourcentage d'abonnés au Bâtiment Artisanal par rapport au nombre d'adhérents en ${selected.year} ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-pourcentage-adherents-employeurs-dep':
            config.push({
                title: `Pourcentage d’adhérents employeurs ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-pourcentage-adherents-travaillant-employeurs-dep':
            config.push({
                title: `Pourcentage d’adhérents ${type == 'travaillant' ? 'travaillant seul' : 'employeurs'} ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-taux-depart-dep':
            config.push({
                title: `Taux de départ ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-taux-nouveaux-adherents-dep':
            config.push({
                title: `Taux des nouveaux adhérents ${!selected.zone ? 'moyen' : ''} en ${selected.year} ${
                    selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''
                }`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-dep':
        case 'capeb-montant-cotisations-syndicales-totales-produits-exploitation-reg':
            config.push({
                title: `Montant des cotisations syndicales totales / produits d’exploitation ${
                    !selected.zone ? 'moyen' : ''
                } en ${selected.year} ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
        case 'capeb-nombre-collaborateur-etp-dep':
        case 'capeb-nombre-collaborateur-etp-reg':
            config.push({
                title: 'Nombre de collaborateurs ETP en ' + selected.year,
                value: customRound(selected.total, graph.type),
                label: addSpacesToNumber(customRound(selected.total, graph.type)),
            });
            break;
        case 'capeb-montant-cotisation-fixe-dep':
        case 'capeb-cotisations-syndicales-totales-dep':
        case 'capeb-montant-cotisation-fixe-reg':
        case 'capeb-cotisations-syndicales-totales-reg':
            config.push({
                title: `Montant moyen de la cotisation en ${selected.year}`,
                value: customRound(selected.total, graph.type),
                label: addSpacesToNumber(customRound(selected.total, graph.type)) + ' €',
            });
            break;
        case 'resultat-net-dep':
        case 'capeb-resultat-net-reg':
            config.push({
                title: `Résultat net moyen en ${selected.year}`,
                value: customRound(selected.total, graph.type),
                label: addSpacesToNumber(customRound(selected.total, graph.type)) + ' €',
            });
            break;
        case 'capeb-montant-cotisation-variable-dep':
        case 'capeb-montant-cotisation-variable-reg':
            config.push({
                title: `Part variable unitaire en ${selected.year}`,
                value: customRound(selected.total, graph.type),
                label: addSpacesToNumber(customRound(selected.total, graph.type)) + '%',
            });
            break;
        case 'capeb-subventions-contributions-exportation-produits-exportations-dep':
        case 'capeb-subventions-contributions-exportation-produits-exportations-reg':
            config.push({
                title: `Subventions et contributions d’exploitation / produits d’exploitation ${
                    !selected.zone ? 'moyen' : ''
                } en ${selected.year} ${selected.zone ? ' pour la CAPEB ' + selected.zone.name : ''}`,
                value: customRound(selected.percent, graph.type),
                label: addSpacesToNumber(customRound(selected.percent, graph.type)) + '%',
            });
            break;
    }

    return <ResumeBlock data={config} {...props} />;
};

export default CapebResumeBlock;
