import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Histogram from '../Histogram';

import { useProjectContext } from '../../context/ProjectContext';

export const RenderedHistogram = ({ colors, items, question, className = '', ...rest }) => {
  
  if (!items) {
    return null;
  }

  let purple = '#c6362d';
  let yellow = '#dc9124';
  let green = '#5ea895';
  let blue = '#5899d4';
  if (colors) {
    purple = colors.ternary;
    yellow = colors.secondary;
    green = colors.plus;
    blue = colors.quaternary;
  }
  
  const series = {
    '3': {
      name: `Systématiquement mis en oeuvre`,
      data: [],
      color: green,
    },
    '2': {
      name: `Partiellement mis en oeuvre`,
      data: [],
      color: yellow,
    },
    '1': {
      name: `Non mis en oeuvre`,
      data: [],
      color: blue,
    },
    '0': {
      name: `Non concerné`,
      data: [],
      color: purple,
    },
  };
  items.map(({ name, scores }) => {
    if (name) {
      for(let score in series) {
        if (scores[score] !== undefined) {
          series[score].data.push({
            y: scores[score].total,
          });
        } else {
          series[score].data.push({
            y: 0,
            nd: true
          });
        }
      }
    }
  });
  
  const config = {
    chart: {
      height: 500,
    },
    title: {
      text: (question || '').replace('Developpement', 'Développement'),
    },
    subtitle: {
      text: 'Comparaison des scores, par taille et par zone',
    },
    xAxis: {
      categories: items.map(({ name }) => (name || '').replace('||', '<br />'))
    },
    yAxis: {
      min: 0,
      // title: {
      //   text: 'Count trophies'
      // },
      labels: {
        format: '{value}%'
      },
      max: 125,
      stackLabels: {
        enabled: false
      }
    },
    legend: {
    },
    tooltip: {
      headerFormat: '',
      // pointFormat: '{series.name}: {point.percentage:,.2f} %',
      pointFormatter: function() {
        if (this.percentage === undefined) {
          return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>NR</b><br/>`;
        }
        return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${Math.round(this.percentage)}%</b><br/>`;
      }
    },
    plotOptions: {
      column: {
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          formatter: function (point) {
            if (this.percentage === undefined) {
              return 'NR';
            }
            if (this.y === 0) {
              return '';
            }
            return Math.round(this.percentage) + '%';
          }
        }
      }
    },
    series: Object.values(series).reverse(),
  };
  
  return (<Histogram data={{}} params={config} className={className} {...rest} />); 
};

const QuestionAndHistogram = ({ graph, total, className = '', ...props }) => {
  // ---------------------------------
  // ---------------------------------
  const { colors } = useProjectContext();
  
  // ---------------------------------
  // ---------------------------------
  const renderHistogram = (total) => {
    if (!total) {
      return null;
    }
    
    let { items, question } = total;
    
    return (<RenderedHistogram items={items} question={question} colors={colors} />);
  };
  
  return (
    <div className={`${className}`} {...props}>
      <Container>
        <Row>
          <Col>
            {renderHistogram(total)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuestionAndHistogram;