/* global URLSearchParams */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import slugify from 'slugify';


import '../../assets/styles/pages/capeb/capeb-pdf.scss';
import ApiService from '../../services/api';

import Source from '../../components/capeb/Source';
import { TableauAnalyseDepartementale } from '../../components/capeb/AnalyseDepartementale';
import { RenderedHistogram } from '../../components/capeb/QuestionAndHistogram';
import Loader from '../../components/Loader';



const PdfHeader = ({ pdfInfo, dataYear, capebName }) => {
  return (
    <div className={`capeb-pdf__header`}>
      <Container fluid>
        <Row>
          <Col>
            <div className="capeb-pdf__logo" />
          </Col>
          <Col>
            <h1 className={`capeb-pdf__htitle`}>
              Grille d'auto-évaluation <br />
              Analyse départementale
            </h1>
            <h2 className={`capeb-pdf__hsubtitle`}>
              {capebName}
            </h2>
            <h3 className={`capeb-pdf__hdate`}>
              Année {pdfInfo.editedYear} - Données {dataYear}
            </h3>
          </Col>
        </Row>
      </Container>
    </div>  
  );
}

const ExportPdfCapeb = ({ project }) => {
  let timer = null;
  const [pdfInfo, setPdfInfo] = useState(null);
  const [total, setTotal] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataYear, setDataYear] = useState('');
  const [graphInfo, setGraphInfo] = useState(null);
  
  const location = useLocation();
  const navigate = useNavigate();
  let queryParams = new URLSearchParams(location.search);
  const capebName = queryParams.get('capebName') || '';

  const page1Ref = React.useRef();
  const page2Ref = React.useRef();

  // ------------------------------------------
  // Render
  // ------------------------------------------
  useEffect(() => {
      const dep = queryParams.get('dep') || '';
      loadPdfInfo(dep);
  }, [project]);
  
  
  // ------------------------------------------
  // Load Data
  // ------------------------------------------
  const loadPdfInfo = async (dep) => {
    if (!project) {
      return null;
    }
    setLoading(true);
    
    
    try {
      const pdfInfo = await ApiService.getPdfInfo(project.slug, '', dep);
      setPdfInfo(pdfInfo);
      const graphIds = [];
      
      if (pdfInfo && pdfInfo.sections) {
        for(let { blocks } of pdfInfo.sections) {
          if (blocks) {
            for (let graph of blocks) {
              const { type, graphID } = graph;
              if (type == 'graph' && graphID) {
                graphIds.push(graphID);
                setGraphInfo(graph);
              }
            }
          }
        }
        
        const graphsTotals = await fetchAllGraphData(graphIds, dep);
        const newTotal = {};
        for(let { id, data } of graphsTotals) {
          newTotal[id] = data;
        }
        setTotal(newTotal);
      } else {
        setTotal({});
      }
      setLoading(false);
      
      // print pdf
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        await createPDF();
      }, 1000);
    } catch (e) {
      setPdfInfo(null);
      setTotal({});
      setLoading(false);
    }
  };
  
  
  
  // ------------------------------------------
  // Load Graphs Data
  // ------------------------------------------
  const fetchAllGraphData = async (graphIds, dep) => {
    try {
      // Map des graphIds en une liste de Promises
      const promises = graphIds.map(async (id) => {
        const data = await ApiService.getGraphData(id, dep);
        if (data.year) {
          setDataYear(data.year);
        }
        return { id, data }; // Ajoute l'ID au résultat
      });
  
      // Attente de toutes les Promises avec Promise.all
      const results = await Promise.all(promises);
      return results;
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      // Vous pouvez gérer les erreurs ici
      throw error;
    }
  };
  
  
  
  // ------------------------------------------
  // Create PDF
  // ------------------------------------------
  const createPDF = async () => {
    let element = page1Ref.current;
    let canvas = await html2canvas(element);
    let data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    let imgProperties = pdf.getImageProperties(data);
    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
    pdf.addPage();
    element = page2Ref.current;
    canvas = await html2canvas(element);
    data = canvas.toDataURL('image/png');
    imgProperties = pdf.getImageProperties(data);
    pdfWidth = pdf.internal.pageSize.getWidth();
    pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
    pdf.save(`Analyse_departementale_${(slugify(capebName || 'Ensemble capeb')).toLowerCase()}.pdf`);
    setTimeout(async () => {
      window.close();
    }, 500);
  };


  // ------------------------------------------
  // Render
  // ------------------------------------------
  if (loading || !pdfInfo || !graphInfo || !total) {
    return null;
  }
  const graphTotal = total[graphInfo.graphID];
  const questions = graphTotal.questions || [];
  return (
    <div className={`capeb-pdf ${loading ? '' : 'capeb-pdf--loaded'}`}>
      <Loader fixed={true} />
      <div className={`capeb-pdf__page`} ref={page1Ref}>
      
        <PdfHeader pdfInfo={pdfInfo} dataYear={dataYear} capebName={capebName} />
        
        <div className={`capeb-pdf__content`}>
          <h2 className={`capeb-pdf__gtitle`}>
            1. Indicateurs d'activités et financiers
          </h2>
          <Container fluid>
            <TableauAnalyseDepartementale total={graphTotal} />
          </Container>
          
          
          <h2 className={`capeb-pdf__gtitle`}>
            2. Questionnaire relatif à la Charte du Réseau
          </h2>
          <Container fluid>
            <Row>
              {questions.slice(0, 2).map(question => (
                <Col xs={6} key={question.id}>
                  <h4 className="capeb-pdf__gsubtitle">{question.category}</h4>
                  <RenderedHistogram items={question.items} question={question.category} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
      
      <div className={`capeb-pdf__page`} ref={page2Ref}>
      
        <PdfHeader pdfInfo={pdfInfo} dataYear={dataYear} capebName={capebName} />
        
        <div className={`capeb-pdf__content`}>
          <Container fluid>
            <Row className="align-items-end">
              {questions.slice(2, 5).map(question => (
                <Col xs={6} key={question.id}>
                  <h4 className="capeb-pdf__gsubtitle">{question.category}</h4>
                  <RenderedHistogram items={question.items} question={question.category} />
                </Col>
              ))}
              <Col xs={6}>
                <Source className={'capeb-pdf__source'} graph={ graphInfo ? graphInfo.graphObject : {} } />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ExportPdfCapeb;
